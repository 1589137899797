import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";

class ImgTextRow extends Component {
    render() {
        let { data, navActions } = this.props;

        return (
            <Wrapper
                img={`/images/redesign/${data.image}`}
                reverse={data.reverse}
                subtitlePosition={data.subtitlePosition}
                css='margin-bottom: 100px;'
            >
                <div className="content">
                    {
                        data.subtitlePosition === "top"
                        && <div className="sub-title">{data.subtitle}</div>
                    }
                    <div className="title">{data.title}</div>
                    {
                        data.subtitlePosition === "bottom"
                        && <div className="sub-title">{data.subtitle}</div>
                    }
                    {
                        data.buttonDisplay
                        && <button onClick={() => navActions.push("/products")}>
                            {data.buttonText}
                          </button>}
                </div>
                <div className="image" />
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: ${props => props.reverse ? "row-reverse" : "row"};
    justify-content: space-between;
    align-items: center;
    padding: 100px 0px;

    & > .content {
        max-width: 410px;
        width: 100%;
        margin-right: ${props => props.reverse ? 0 : 25}px;
        & > .title {
            margin-bottom: ${props => props.subtitlePosition === "bottom" ? 15 : 40 }px;
            white-space: pre-wrap;
            font-size: 50px;
            color: #0075C1;

            @media screen and (max-width: 768px) {
                font-size: 40px;
            }

            @media screen and (max-width: 600px) {
                font-size: 30px;
            }
        }

        & > .sub-title {
            margin-bottom: 15px;
            white-space: pre-wrap;
            font-size: 20px;
            color: #575757;

            @media screen and (max-width: 600px) {
                font-size: 12px;
            }

            @media screen and (max-width: 414px) {
                font-size: 14px;
            }

            @media screen and (max-width: 320px) {
                font-size: 12px;
            }
        }

        & > button {
            float: ${props => props.reverse ? 'right' : 'left'};
            cursor: pointer;
            min-width: 116px;
            box-shadow: 0 2px 6px 0 rgba(29,64,117,0.50);
            border: 0px;
            padding: 10px;
            background: #0075C1;
            font-size: 14px;
            color: #FFFFFF;

            :focus {
                outline: none;
            }
        }

        @media screen and (max-width: 414px) {
            width: 100%;
            margin-bottom: 25px;
        }
    }

    & > .image {
        margin-right: ${props => props.reverse ? 25 : 0}px;
        max-width: 650px;
        width: 100%;
        min-height: 460px;
        background-image: url(${props => props.img});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: lightgray;

        @media screen and (max-width: 1024px) {
            max-width: 500px;
            min-height: 353px;
        }

        @media screen and (max-width: 768px) {
            max-width: 400px;
            min-height: 283px;
        }

        @media screen and (max-width: 600px) {
            max-width: 300px;
            min-height: 212px;
        }

        @media screen and (max-width: 414px) {
            margin-right: 0px;
            max-width: 100%;
            min-height: 283px;
        }
    }

    @media screen and (max-width: 600px) {
        padding: 40px 0px;
    }

    @media screen and (max-width: 414px) {
        flex-wrap: wrap;
        margin-right: 0px;
    }
`;

export default connect(
    null,
    ActionCreator
)(ImgTextRow);