import React from 'react';
import Recaptcha from "react-google-recaptcha";
import Constants from '../Domain/constants';

class GoogleRecaptcha extends React.Component {
  render() {
    let {className, size, onChange} = this.props;

    return (
      <>
        <Recaptcha
          className={className}
          sitekey={Constants.googleRecaptchaSiteKey}
          size={size}
          onChange={onChange}
        />
      </>
    );
  }
}

export default GoogleRecaptcha;