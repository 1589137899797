import React from 'react';
import styled from 'styled-components';
import { withPage } from '../Page';
import ImgTextRow from '../Components/ImgTextRow';
import ContactUsSection from '../Components/ContactUsSection';
import ContactUs from '../Components/ContactUs';

const ItemData = [
  {
    title: "A-Z Whole Sale", subtitle: "VoIP(Voice over Internet Protocol)，是一種語音通話技術，經由網際協定（IP）來達成語音通話，也就是經由網際網路來進行通訊。\n\nTEL25提供A-Z各國國家路由對接的服務，利用網路不僅做到了即刻提供語音服務，更可連接至世界各地，讓您不必出國也能和國外客戶即時連線!",
    subtitlePosition: "bottom", buttonDisplay: false, buttonText: "",
    image: "photo13.png", reverse: false,
  },
  {
    title: "Call Center Solution", subtitle: "現今以客為尊的時代，客戶服務為各大用戶最重視的一環，我們與各大業者對接的語音路由，能確保您為您的客戶提供高質量、高效率、全方位的服務。",
    subtitlePosition: "bottom", buttonDisplay: false, buttonText: "",
    image: "photo14.png", reverse: true,
  },
];

class VoicePage extends React.Component {
  render() {
    return (
      <Wrapper>
        <Container>
          {
            ItemData.map((d, i) =>
              <ImgTextRow
                key={'voice' + i}
                data={d}
              />
            )
          }
        </Container>

        <ContactUsSection />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 150px 0px 50px;
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0px 25px 150px;
  max-width: 1120px;
`;

export default withPage(VoicePage);
