export function validateEmail(email) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateMobileNumber(phone) {
  let re = /^09([0-9]{8})$/;
  return re.test(phone);
}

export function validateReceiverName(name) {
  let engRe = /^([a-zA-Z]{4,10})$/;
  let notEngRe = /^([^a-zA-Z0-9]{2,5})$/;
  if (engRe.test(name)) {
    return true;
  } else {
    // chinese character
    return notEngRe.test(name);
  }
}

export function validateTaxId(taxId) {
  // taxId could either be blank or 8 numbers
  let re = /^(\d{8}|)$/;
  return re.test(taxId)
}
