import React from 'react';
import styled from 'styled-components';
import * as Icon from '../Components/Icon';
import ContactUsForm from '../Components/ContactUsForm';

class ContactUsSection extends React.Component {
    render() {
        return (
            <Wrapper>
                <Container>
                    <div className='left'>
                        <div className='title'>二十五電訊有限公司</div>
                        <div className='item'>
                            <Icon.Phone color='white' style={{ width: 24, height: 24, marginRight: 5 }} />
                            <span>+886-2-25211005</span>
                        </div>
                        <div className='item'>
                            <Icon.Email color='white' style={{ width: 24, height: 24, marginRight: 5 }} />
                            <span>info@tel25.com</span>
                        </div>
                        <div className='item'>
                            <Icon.LocationOn color='white' style={{ width: 24, height: 24, marginRight: 5 }} />
                            <span>104 台北市中山區民權東路二段148號4樓</span>
                        </div>
                    </div>
                    <div className='right'>
                        <ContactUsForm />
                    </div>
                </Container>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    width: 100vw;
    background-color: #0075C1;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 50px;
    margin: 0 auto;
    max-width: 1120px;

    & > .left {
        margin-right: 80px;
        max-width: 320px;
        width: 100%;

        & > .title {
            margin-bottom: 20px;
            letter-spacing: 1.2px;
            font-size: 20px;
            color: #FFFFFF;
        }

        & > .item {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            & > span {
                font-size: 14px;
                color: white;
            }
        }
    }

    & > .right {
        max-width: 700px;
        width: 100%;
    }

    @media screen and (max-width: 950px){
        flex-wrap: wrap;
        padding: 50px 25px;

        & > .left {
            margin-right: 0px;
            margin-bottom: 50px;
        }
    }
`;

export default ContactUsSection;