import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import ActionCreator from '../ActionCreator';
import * as Unstated from '../Utils/Unstated';
import * as FormUtil from '../Utils/FormUtil';
import * as L from '../Utils/Lang';
import * as Widget from './Widget';
import Recaptcha from './GoogleRecaptcha';

class ContactUsForm extends React.Component {
    render() {
        let { appActions } = this.props;

        return (
            <Wrapper>
                <div className='title'>聯絡我們</div>
                <div className='text'>我們將在一個工作天內回覆您</div>
                <Form appActions={appActions} />
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    padding: 50px;
    width: 100%;
    background-color: #FFFFFF;

    & > .title {
        margin-bottom: 15px;
        letter-spacing: 1.8px;
        font-size: 30px;
        color: #0075C1;
    }

    & > .text {
        margin-bottom: 20px;
        font-size: 20px;
        color: #575757;
    }

    @media screen and (max-width: 599px) {
        padding: 50px 20px;

        & > .text {
            font-size: 16px;
        }
    }
`;

class Form extends React.Component {
    initialValues = {
        name: '',
        phone: '',
        email: '',
        type: 'order',
        message: '',
    };

    initialMessageState = {
        display: false,
        failure: false,
        text: '',
    };

    state = {
        message: { ...this.initialMessageState },
        recaptcha: '',
        recaptchaKey: 0,
    }

    render() {
        let { message, recaptchaKey } = this.state;
 
        return (
            <Form.Wrapper>
                <Unstated.Form
                    init={() => ({
                        values: { ...this.initialValues },
                        editing: false,
                    })}
                    submit={this._SubmitForm}
                >
                    {({ values, errors, editProps, inputProps, submitProps }) => {
                        return (
                            <>
                                <Widget.Row
                                    className='name-phone'
                                    extraCss='margin-bottom: 15px;'
                                >
                                    <Form.Input
                                        {...inputProps.name}
                                        placeholder={L.s(`name`)}
                                        className='name'
                                        css='margin-right: 30px;'
                                    />
                                    <Form.Input
                                        {...inputProps.phone}
                                        placeholder={L.s(`phone`)}
                                    />
                                </Widget.Row>

                                <Widget.Col>
                                    <Form.Input
                                        {...inputProps.email}
                                        placeholder={L.s(`email`)}
                                        css='margin-bottom: 15px;'
                                    />
                                    <Form.Select
                                        {...inputProps.type}
                                        css='margin-bottom: 15px;'
                                    >
                                        <option value="order">{L.s(`order-problem`)}</option>
                                        <option value="product">{L.s(`product-problem`)}</option>
                                        <option value="voice">{L.s(`voice-problem`)}</option>
                                        <option value="member">{L.s(`member-problem`)}</option>
                                        <option value="other">{L.s(`other-problem`)}</option>
                                    </Form.Select>
                                    <Form.TextArea
                                        {...inputProps.message}
                                        placeholder='請寫下您的意見 ...'
                                        css='margin-bottom: 15px;'
                                    />
                                </Widget.Col>

                                <Widget.Col align='flex-end'>
                                    <Widget.Col className='recaptcha' extraCss='margin-bottom: 15px;'>
                                        <Recaptcha
                                            key={recaptchaKey}
                                            onChange={value =>
                                                this.setState({ recaptcha: value })
                                            }
                                        />
                                    </Widget.Col>
                                   

                                    {message.display && (
                                        <Widget.Center
                                            extraCss={`
                                                margin-bottom: 10px;
                                                color: ${message.failure ? 'red' : 'blue'};
                                                font-size: 13px;
                                            `}
                                        >
                                            {message.text}
                                        </Widget.Center>
                                    )}

                                    {submitProps.submitting ? (
                                        <Widget.Center>
                                            <Widget.Spinner />
                                        </Widget.Center>
                                    ) : (
                                        <Widget.BasicButton onClick={() => submitProps.submit()}>
                                            {L.s('submit')}
                                        </Widget.BasicButton>
                                    )}
                                </Widget.Col>
                            </>
                        )
                    }}

                </Unstated.Form>
            </Form.Wrapper>
        )
    }

    _resetRecaptcha = () => {
        let { recaptchaKey } = this.state;
        this.setState({ recaptchaKey: recaptchaKey + 1 })
    }

    _SubmitForm = values => {
        let { appActions } = this.props;
        let { recaptcha } = this.state;

        if (Object.keys(values).some(key => !values[key])) {
            this.setState({
                message: {
                    display: true,
                    failure: true,
                    text: L.s(`all-fields-required`,)
                },
            });
            return Promise.resolve();
        }

        if (!FormUtil.validateEmail(values.email)) {
            this.setState({
                message: {
                    display: true,
                    failure: true,
                    text: L.s(`enter-valid-email`)
                }
            })
            return Promise.resolve();
        }

        this.setState({ message: { ...this.initialMessageState } })
        return appActions.postContactUs({ ...values, recaptcha })
                .then(result => {
                    this._resetRecaptcha();
                    this.setState({
                        message: { display: true, text: L.s(`submit-success`) }
                    })

                    return Promise.resolve({
                        values: { ...this.initialValues }
                    });
                })
                .catch(err => {
                    if (err.status === 471) {
                        this._resetRecaptcha();
                        return this.setState({
                            message: {
                                display: true,
                                failure: true,
                                text: L.s(`recaptcha-error`)
                            },
                        });
                    }

                    return this.setState({
                        message: {
                            display: true,
                            failure: true,
                            text: L.s(`submit-failure`)
                        },
                    });
                });
    };

    static Wrapper = styled.div`
        @media screen and (max-width: 490px) {
            & > .name-phone {
                flex-direction: column;

                & > .name {
                    margin-right: 0px;
                    margin-bottom: 15px;
                }
            }

            & .recaptcha {
                transform: scale(0.75);
                transform-origin: top right;

            }
        }
    `;

    static InputCss = css`
        width: 100%;
        outline: none;
        border-radius: 3px;
        border: 1px solid #D2D2D2;
        background: #FFFFFF;
        font-size: 14px;
        color: black;
    `;

    static Input = styled.input`
        padding: 9px;
        ${Form.InputCss}
        ${props => props.css};

        ::placeholder {
            color: lightgray;
        }
    `;

    static Select = styled.select`
        padding: 9px;
        height: 40px;
        ${Form.InputCss}
        ${props => props.css};
    `;

    static TextArea = styled.textarea`
        padding: 9px;
        height: 200px;
        resize: none;
        ${Form.InputCss}

        ::placeholder {
            color: lightgray;
        }

        ${props => props.css};
    `;
}

export default connect(
    null,
    ActionCreator
)(ContactUsForm);