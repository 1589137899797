import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import ActionCreator from '../ActionCreator';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as Widget from './Widget';
import * as Unstated from '../Utils/Unstated';
import * as L from '../Utils/Lang';
import * as FormUtil from '../Utils/FormUtil';
import Recaptcha from './GoogleRecaptcha';

class ContactUs extends React.Component {
  render() {
    let { extraCss, appActions } = this.props;

    return (
      <Wrapper extraCss={extraCss}>
        <div className="title">聯絡我們</div>

        <div className="content">
          <div className="info">
            <div className="text">
              {
                '感謝您對TEL25的支持與愛護，如果您有任何意見與問題，都非常歡迎您提出，我們將竭誠為您服務！\n\n請填寫表單，留下您的聯繫方式，我們將會盡快回覆您。'
              }
            </div>

            <div className="placeholder" />

            <div className="image">
              <Widget.BgImage
                extraCss="background-color: inherit;"
                image={PathPrefix('/images/voice/happiness.png')}
              />
            </div>
          </div>

          <Form appActions={appActions} />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 50px 20px 70px;

  & > .title {
    margin-bottom: 30px;
    color: #3f8dcf;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }

  & > .content {
    display: flex;
    justify-content: center;

    & > .info {
      width: 400px;
      max-width: 100%;
      margin-right: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .text {
        width: 100%;
        color: #343434;
        font-size: 16px;
        line-height: 25px;
        text-align: justify;
        white-space: pre-wrap;
      }

      & > .placeholder {
        flex-grow: 1;
      }

      & > .image {
        width: 350px;
        height: 350px;
        margin-top: 90px;
      }
    }
  }

  @media screen and (max-width: 950px) {
    & > .content {
      flex-direction: column;
      align-items: center;

      & > .info {
        margin-right: 0;
        margin-bottom: 50px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    & > .content > .info > .image {
      width: 150px;
      height: 150px;
      margin-top: 65px;
    }
  }

  ${props => props.extraCss};
`;

class Form extends React.Component {
  initialValues = {
    name: '',
    phone: '',
    email: '',
    type: 'order',
    message: '',
  };

  initialMessageState = {
    display: false,
    failure: false,
    text: '',
  };

  state = {
    message: { ...this.initialMessageState },

    recaptcha: '',
    recaptchaKey: 0,
  }

  render() {
    let { extraCss } = this.props;
    let { message, recaptchaKey } = this.state;

    return (
      <Form.Wrapper extraCss={extraCss}>
        <Unstated.Form
          init={() => ({
            values: { ...this.initialValues },
            editing: false,
          })}
          submit={this._SubmitForm}
        >
          {({ values, errors, editProps, inputProps, submitProps }) => {
            return (
              <>
                <Widget.Row
                  className="name-phone"
                  extraCss="margin-bottom: 15px;"
                >
                  <Widget.Col
                    className="name"
                    extraCss="flex: 1;margin-right: 30px"
                  >
                    <Form.Label>{L.s(`name`)}</Form.Label>
                    <Form.Input {...inputProps.name} />
                  </Widget.Col>

                  <Widget.Col extraCss="flex: 1;">
                    <Form.Label>{L.s(`phone`)}</Form.Label>
                    <Form.Input {...inputProps.phone} />
                  </Widget.Col>
                </Widget.Row>

                <Widget.Col extraCss="margin-bottom: 15px;">
                  <Form.Label>{L.s(`email`)}</Form.Label>
                  <Form.Input {...inputProps.email} />
                </Widget.Col>

                <Widget.Col extraCss="margin-bottom: 15px;">
                  <Form.Label>{L.s(`type`)}</Form.Label>
                  <Form.Select {...inputProps.type}>
                    <option value="order">{L.s(`order-problem`)}</option>
                    <option value="product">{L.s(`product-problem`)}</option>
                    <option value="voice">{L.s(`voice-problem`)}</option>
                    <option value="member">{L.s(`member-problem`)}</option>
                    <option value="other">{L.s(`other-problem`)}</option>
                  </Form.Select>
                </Widget.Col>

                <Widget.Col extraCss="margin-bottom: 15px;">
                  <Form.Label>{L.s(`message`)}</Form.Label>
                  <Form.TextArea {...inputProps.message} />
                </Widget.Col>

                <Widget.Col extraCss="align-items: flex-end;">
                  <Widget.Col className='recaptcha' extraCss="margin-bottom: 15px;">
                    <Recaptcha
                      key={recaptchaKey}
                      onChange={value => this.setState({ recaptcha: value })}
                    />
                  </Widget.Col>

                  {message.display && (
                    <Widget.Center
                      extraCss={`
                        margin-bottom: 10px;
                        color: ${message.failure ? 'red' : 'blue'};
                        font-size: 13px;
                      `}
                    >
                      {message.text}
                    </Widget.Center>
                  )}

                  {submitProps.submitting ? (
                    <Widget.Center>
                      <Widget.Spinner />
                    </Widget.Center>
                  ) : (
                    <Form.Button onClick={() => submitProps.submit()}>
                      {L.s('submit')}
                    </Form.Button>
                  )}
                </Widget.Col>
              </>
            );
          }}
        </Unstated.Form>
      </Form.Wrapper>
    );
  }

  _resetRecaptcha = () => {
    let { recaptchaKey } = this.state;
    this.setState({ recaptchaKey: recaptchaKey + 1 })
  }

  _SubmitForm = values => {
    let { appActions } = this.props;
    let { recaptcha } = this.state;

    if (Object.keys(values).some(key => !values[key])) {
      this.setState({
        message: {
          display: true,
          failure: true,
          text: L.s(`all-fields-required`),
        },
      });
      return Promise.resolve();
    }

    if (!FormUtil.validateEmail(values.email)) {
      this.setState({
        message: {
          display: true,
          failure: true,
          text: L.s(`enter-valid-email`),
        },
      });
      return Promise.resolve();
    }

    this.setState({ message: { ...this.initialMessageState } });
    return appActions
      .postContactUs({ ...values, recaptcha })
      .then(result => {
        this._resetRecaptcha();
        this.setState({
          message: { display: true, text: L.s(`submit-success`) },
        })

        return Promise.resolve({
          values: { ...this.initialValues },
        });
      })
      .catch(err => {
        if (err.status === 471) {
          this._resetRecaptcha();
          return this.setState({
            message: {
              display: true,
              failure: true,
              text: L.s(`recaptcha-error`),
            },
          });
        }

        return this.setState({
          message: {
            display: true,
            failure: true,
            text: L.s(`submit-failure`),
          },
        });
      });
  };

  static Wrapper = styled.div`
    position: relative;
    width: 450px;
    max-width: 100%;
    padding: 40px 20px 30px;
    border: 4px solid #3f8dcf;
    background: #fff;

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      width: 40px;
      height: 40px;
      border: inherit;
      border-top: none;
      border-right: none;
      background: inherit;
    }

    &::after {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      width: 56.57px;
      border: inherit;
      border-right: none;
      border-left: none;
      border-bottom: none;
      transform: translateY(40px) rotate(45deg);
      transform-origin: top right;
    }

    @media screen and (max-width: 490px) {
      & > .name-phone {
        flex-direction: column;

        & > .name {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
      
      & .recaptcha {
        transform: scale(0.75);
        transform-origin: top right;
        margin-bottom: 0;
      }
    }

    ${props => props.extraCss};
  `;

  static Label = styled.div`
    margin-bottom: 1px;
    color: #343434;
    font-size: 16px;
  `;

  static InputCss = css`
    width: 100%;
    outline: none;
    border-radius: 5px;
    border: none;
    background: #f4f4f4;
    color: #808080;
    font-size: 16px;
  `;

  static Input = styled.input`
    ${Form.InputCss} padding: 6px;
  `;

  static Select = styled.select`
    ${Form.InputCss} height: 30px;
  `;

  static TextArea = styled.textarea`
    ${Form.InputCss} height: 103px;
    padding: 6px;
    resize: none;
  `;

  static Button = styled.button`
    border-radius: 7px;
    padding: 8px 22px 7px;
    background: #3f8dcf;
    box-shadow: 0 2px 4px 0 rgba(0, 135, 241, 0.5);
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
  `;
}

export default connect(
  null,
  ActionCreator
)(ContactUs);
